import { functions} from '../common/firebase-common';
import { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../store/user-context';
import Backdrop from '../components/Backdrop';
import './CreateGame.css';

function parsePlayers(list) {
  return list ? list.split(",").map( e => e.trim()).filter( e => e !== "" ): [];
}
const createGameFun = functions.httpsCallable('createGame');

function CreateGame() {
  const [startGame, setStartGame] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formRef = useRef();
  const history = useHistory();
  const userContext = useContext(UserContext);

  useEffect(()=>{
    if(!userContext.user || userContext.user === null) {
      history.replace({ pathname: '/login', state: {next:'/create'}});
      return;
    }
  }, [history, userContext]);

  function handleSubmit(event) {
    event.preventDefault();
    setCreating(true);
    const entry = {
      "title": formRef.current.title.value,
      "description": formRef.current.description.value,
      "game": formRef.current.game.value,
      "players": parsePlayers(formRef.current.players.value),
      "startGame": startGame 
    }
    createGameFun(entry)
    .then( result => {
      if(!result.data.error) {
        if(startGame) {
          history.push('/score/' + result.data.gameId);
        } else {
          history.push('/games');
        }
      } else {
        setErrorMessage("Failed to create Game - " + result.data.errMsg );
        setCreating(false);
      }
    });
  }

  function create() {
    setStartGame(false);
  }

  function createAndStart(event) {
    setStartGame(true);
  }
  if(isCreating) {
    return (
      <div className="ManagedGames">
          <Backdrop />
          <p>Waiting for response....</p>
      </div>
    );
  }
  return (
    <form className="form" onSubmit={handleSubmit} ref={formRef}>
      <h1>Create Game</h1>
  <div className="createErrorMsg">{errorMessage}</div>
      <div className="control">
        <label htmlFor="game">Game</label>
        <select id="game" required>
          <option value="scrabble">Scrabble</option>
          <option value="other">Other</option>
        </select>
      </div>
      <div className="control">
        <label htmlFor="title">Title</label>
        <input type="text" required id="title"></input>
      </div>
      <div className="control">
        <label htmlFor="players">Players<span>(comma seperated)</span></label>
        <input type="text" required id="players"></input>
      </div>
      <div className="control">
        <label htmlFor="">Description<span>(optional)</span></label>
        <textarea id="description" rows="5" ></textarea>
      </div>
      <div className="actions">
        <button onClick={create}>Create</button>
        <button onClick={createAndStart}>Create and Start</button>
      </div>
    </form>
  );
}
  
export default CreateGame;
