
function GameViewCard(props) {
    return(
        <div>
            {props.title}, {props.game}, {props.status}
            <button>View</button>
        </div>
    );
}

export default GameViewCard;
