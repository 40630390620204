import firebase from '../common/firebase-common';
import 'firebase/database';
import { useState, useEffect } from 'react';

function ScoreGame(props) {
  const [scoreTotal, setScoreTotal] = useState("0");

  useEffect(() => {
    const boardRef = firebase.database().ref().child("games/-M_cs6GEUjoLwpIchsSN");
    boardRef.on('value', snap => {
      console.log(JSON.stringify(snap.val(), null, 3));
      setScoreTotal(JSON.stringify(snap.val(), null, 3));
    });
  }, []);
  return (
    <div className="ScoreGame">
      Score {scoreTotal} for game {props.match.params.gameId};
    </div>
  );
}
  
export default ScoreGame;
