import './Modal.css'

function Modal(props) {
    return (
      <div className="Modal">
          <p>{props.displayText}</p>
          <button onClick={props.onCancel}>Cancel</button>
          <button onClick={props.onConfirm}>Confirm</button>
      </div>
    );
  }

  export default Modal;