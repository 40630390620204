import { Link } from 'react-router-dom';
import './MainNavigation.css';

function MainNavigation(props) {
    return (
        <header className="navheader">
            <div className="navlogo">Game Scores</div>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/create">Create</Link></li>
                    <li><Link to="/games">Manage</Link></li>
                    <li><Link to="/profile">Profile</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default MainNavigation;