import { createContext, useState } from 'react';

const UserContext = createContext({
    // helpful for auto completion
    user: null,
    onLogin: (user) => {},
    onLogout: () => {}

}); 

export function UserContextProvider(props) {
    const [userContext, setUserContext] = useState(null);

    function handleLogin(user) {
        setUserContext(user);
    }

    function handleLogout() {
        console.log("signout called");
        setUserContext(null);
    }

    const context = {
        user: userContext,
        onLogin: handleLogin,
        onLogout: handleLogout
    };
    return <UserContext.Provider value={context}>
        {props.children}
    </UserContext.Provider>
}

export default UserContext;