import { Route, Switch } from 'react-router-dom'
import SearchGame from './pages/SearchGame';
import ManagedGames from './pages/ManagedGames';
import CreateGame from './pages/CreateGame';
import ScoreGame from './pages/ScoreGame';
import Login from './pages/Login';
import MainNavigation from './components/MainNavigation';
import './App.css';

function App() {
  return (
    <div className="App">
      <MainNavigation />
      <div className="AppContent">
        <Switch>
          <Route path="/" exact>
            <SearchGame />
          </Route>
          <Route path="/games">
            <ManagedGames />
          </Route>
          <Route path="/create">
            <CreateGame />
          </Route>
          <Route path="/score/:gameId" component={ScoreGame} />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/profile">
            <Login />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
