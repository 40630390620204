import { functions, database} from '../common/firebase-common';
import { GameStatus } from '../common/constants';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Backdrop from './Backdrop';
import Modal from './Modal';

import './GameCard.css';
import scrabbleLogo from '../resources/scrabble.jpg';

const cancelGameFun = functions.httpsCallable('cancelGame');
const startGameFun = functions.httpsCallable('startGame');

let currentOp = null;

function GameCard(props) {
  const [isModalOpen,  setIsModelOpen] = useState(false);
  const [gameDetails,  setGameDetails] = useState(props);
  const history = useHistory();


  useEffect(()=> {
    database.ref().child("games/" + props.gameId).on("value", snap => {
      setGameDetails(snap.val());
    });
    //return function cleanup() {

    //}
  }, [props.gameId]);

  function handleStartClick() {
    currentOp = startGameFun;
    setIsModelOpen(true);
  }
  
  function handleCancelClick() {
    currentOp = cancelGameFun;
    setIsModelOpen(true);
  }
  
  function handleScoringClick() {
    history.push("/score/"+ props.gameId);
  }
  
  function handleModelConfirm() {
    currentOp(props.gameId)
    .then( res => {
      setIsModelOpen(false);
      props.invokeReload();
    });
  }
  
  function handleModelCancel() {
    setIsModelOpen(false);
  }

  const startButton = <button onClick={handleStartClick}>Start</button> ;
  const cancelButton = <button onClick={handleCancelClick}>Cancel</button>;
  const scoreButton = <button onClick={handleScoringClick}>Score</button> ;
  const getStatusString = () => {
      if(gameDetails.status === GameStatus.SCHEDULED) {
        return "Scheduled";
      } else if(gameDetails.status === GameStatus.LIVE) {
        return "\u2B55 LIVE since " + new Intl.DateTimeFormat("en-us", {dateStyle: "full", timeStyle: "short"}).format(props.startTime);
      }
  };

  return (
    <div className="GameCard" key={props.id}>
      <div className="GameImage">
        <img src={scrabbleLogo} alt={gameDetails.game} />
      </div>
      <div className="GameContent">
        <h3>{gameDetails.title}</h3>
        <h4>{gameDetails.game} - {JSON.stringify(gameDetails.players, null, 3)}</h4>
        <h4>{getStatusString()}</h4>      
        <p>{gameDetails.description}</p>
        {gameDetails.status==="LIVE" ?  scoreButton: startButton}
        {gameDetails.status==="SCHEDULED" ? cancelButton : null }
      </div>
      {isModalOpen ? <Backdrop onBackdropClick={handleModelCancel} /> : null}
      {isModalOpen ? <Modal displayText="Are you sure?" onCancel={handleModelCancel} onConfirm={handleModelConfirm} /> : null}      
    </div>
  );
}

export default GameCard;
