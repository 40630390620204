import firebase from '../common/firebase-common';
import 'firebase/database';
import GameCard from "../components/GameCard";
import GameViewCard from "../components/GameViewCard";

function SearchGame() {
  
    return (
      <div className="SearchGame">
          Search Game
          <GameViewCard title="Pinku vs Parijat" />
          <GameViewCard title="Pinku vs Soniya" />
      </div>
    );
  }
  
export default SearchGame;
