import firebase from '../common/firebase-common';
import {auth} from '../common/firebase-common';
import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledFirebaseAuth } from "react-firebaseui";
import UserContext from "../store/user-context";
import './Login.css';


const uiConfig = {
    signInFlow: 'popup',
    callbacks: {
        signInSuccessWithAuthResult: () => false
    },
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ]
};

function Login(props) {
    const location = useLocation();
    const userContext = useContext(UserContext);
    const history = useHistory();

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        if(userContext.user && userContext.user != null) {
            return;
        }
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            if( user && user != null ) {
                userContext.onLogin(user);
                if(location.state && location.state.next) {
                    history.replace(location.state.next);
                }
            }
        });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.

    }, [userContext, history, location]);

    function handleLogoutClick() {
        auth.signOut();
        userContext.onLogout();
    }
  
    if (!userContext.user || userContext.user == null) {
      return (
        <div className="Login">
          <p>Please sign-in:</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      );
    } else {
        return (
            <div className="Login">
                <p>Welcome {userContext.user.displayName}!</p>
                <p>{userContext.user.email}</p>
                <p><img src={userContext.user.photoURL} alt=""/></p>
                <button onClick={handleLogoutClick}>Sign-out</button>
            </div>
        ); 
    } 
}

export default Login;