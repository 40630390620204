import { functions } from '../common/firebase-common';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Backdrop from '../components/Backdrop';
import GameCard from "../components/GameCard";
import UserContext from '../store/user-context';

const getGamesForUser = functions.httpsCallable('getGamesForUser');

function ManagedGames(props) {
  const [games, setGames] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const userContext = useContext(UserContext);

  function reload() {
    setLoading(true);
  }

  useEffect(()=>{
    if(!userContext.user || userContext.user === null) {
      history.replace({ pathname: '/login', state: {next:'/games'}});
      return;
    }
    getGamesForUser()
    .then( resp => {
      const data = [];
      for(const key in resp.data) {
        const entry = resp.data[key];
        entry.gameId = key;
        data.push(entry);
      }
      setLoading(false);
      setGames(data);
    });  
  }, [userContext, history, isLoading]);

  if(isLoading) {
    return (
      <div className="ManagedGames">
          <Backdrop />
          <p>Loading....</p>
      </div>
    );
  } else {
    return (
      <div className="ManagedGames">
          {games.map( g => { return <GameCard key={g.gameId} {...g} invokeReload={reload} />; })}
      </div>
    );
  }
}
  
export default ManagedGames;
