import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBlQFxKXPNrokEO_GHzmYnD09QvSBlieRc",
    authDomain: "fir-demo-9b59c.firebaseapp.com",
    databaseURL: "https://fir-demo-9b59c-default-rtdb.firebaseio.com",
    projectId: "fir-demo-9b59c",
    storageBucket: "fir-demo-9b59c.appspot.com",
    messagingSenderId: "467934905647",
    appId: "1:467934905647:web:a3c27362e36036e0718dda"
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
if(window.location.hostname === "localhost") {
    console.log("Using auth emulator");
    auth.useEmulator("http://localhost:9099");
}

export const functions = app.functions();
if(window.location.hostname === "localhost") {
    console.log("Using functions emulator");
    functions.useEmulator("localhost", 5001);
}

export const database = app.database();
if (window.location.hostname === "localhost") {
    database.useEmulator("localhost", 9000);
}

export default firebase;