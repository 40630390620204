
export const GameStatus = {
    SCHEDULED: "SCHEDULED",
    LIVE: "LIVE",
    OVER: "OVER"
};

export const Games = {
    scrabble:{ 
        name: "Scrabble",
        image: ""
    },
    quirkle: {
        name: "Quirkle",
        image: ""
    },
}